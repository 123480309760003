<template>
  <div class="deploy">
    <div class="deploy-bg">
      <h2>私有化部署，一次购买，永久使用</h2>
      <p>
        私有化部署，是一种支持系统本地化的部署方式，提供对数据的安全性和服务质量的最有效控制。<br />
        其部署在企业内部物理主机上，从而使数据更加安全可控，是企业的专有资源。
      </p>
      <p>
        星云文档提供了私有化部署的途径，让企业在协作办公，操作文档上数据不通过外网共享的方式获取，<br />
        而是在企业内部局域网上进行传输操作，有效的隔绝数据泄漏的风险。
      </p>
      <button @click="visible = true">立即预约部署方案</button>
    </div>
    <div class="product-superiority">
      <div class="title">
        <h2>PRODUCT SUPERIORITY</h2>
        <h3>产品优势</h3>
      </div>
      <div class="line"></div>
      <div class="card-top">
        <div class="card">
          <img src="@/assets/about/一键部署.png" alt="一键部署" />
          <h4>一键部署</h4>
          <p>提供一键部署脚本，部署简单，移植性强</p>
        </div>
        <div class="card">
          <img src="@/assets/about/安全可控.png" alt="安全可控" />
          <h4>安全可控</h4>
          <p>系统部署在本地，安全性更高，数据更安全可控</p>
        </div>
        <div class="card">
          <img src="@/assets/about/个性化定制.png" alt="个性化定制" />
          <h4>个性化定制</h4>
          <p>可根据企业实际应用场景满足企业定制化需求</p>
        </div>
      </div>
      <div class="card-bottom">
        <div class="card">
          <img src="@/assets/about/数据隔离.png" alt="数据隔离" />
          <h4>数据隔离</h4>
          <p>实现内外网数据隔离，局域网+外网办公发挥综合优势</p>
        </div>
        <div class="card">
          <img src="@/assets/about/永久使用.png" alt="永久使用" />
          <h4>永久使用</h4>
          <p>一次购买，永久使用</p>
        </div>
        <div class="card">
          <img src="@/assets/about/二次开发.png" alt="二次开发" />
          <h4>二次开发</h4>
          <p>用户可在部署服务的基础上进行自主二次开发，扩展性更强</p>
        </div>
      </div>
    </div>
    <Modal :visible="visible" @close="visible = false" />
  </div>
</template>

<script>
import Modal from "./Modal.vue";
export default {
  name: "deploy",
  components: {
    Modal,
  },
  props: {},
  data() {
    return {
      visible: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.white-wrap {
  background-color: #ffffff;
}
.undertint-wrap {
  background-color: #f5f7fa;
}
.default-width {
  width: 1200px;
  margin: 0 auto;
}

.deploy-bg {
  width: 100%;
  min-width: 1200px;
  height: 640px;
  background: url(~@/assets/about/banner3.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 42px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    margin-top: 173px;
    margin-bottom: 24px;
  }
  p {
    width: 990px;
    font-size: 22px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 48px;
    text-align: center;
  }
  button {
    width: 300px;
    height: 58px;
    line-height: 58px;
    border-radius: 8px;
    border: none;
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    background: linear-gradient(to right, #708aff, #4a61ff);
    cursor: pointer;
  }
  button:hover {
    background: linear-gradient(to right, #5e7af9, #354cdf);
  }
  button:active {
    background: linear-gradient(to right, #536cdc, #1e2f9f);
  }
}

.product-superiority {
  .default-width();
  height: 1086px;
  .title {
    position: relative;
    margin-top: 80px;
    h2 {
      font-size: 70px;
      line-height: 70px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #999999;
      text-align: center;
      opacity: 0.1;
    }
    h3 {
      position: absolute;
      left: 50%;
      top: 15px;
      transform: translateX(-50%);
      font-size: 40px;
      line-height: 40px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 600;
      color: #313544;
    }
  }
  .line {
    width: 40px;
    height: 3px;
    margin: 5px auto 80px;
    background: #4a61ff;
  }
  .card-top,
  .card-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 48px;
  }
  .card {
    width: 386px;
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 20px 0px rgba(205, 205, 205, 0.41);
    border-radius: 10px;
    img {
      margin-top: 36px;
      margin-bottom: 36px;
      width: 88px;
      height: 88px;
    }
    h4 {
      font-size: 30px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 31px;
    }
    p {
      width: 332px;
      font-size: 20px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      text-align: center;
    }
  }
  .card:hover {
    background: linear-gradient(to right, #708aff, #4a61ff);
    h4 {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
}
</style>
