<template>
  <div class="modal">
    <a-modal
      :visible="visible"
      :width="756"
      :maskClosable="false"
      :centered="true"
      @ok="handleOk"
      @cancel="handleCancle"
    >
      <template slot="title">
        <div class="title">预约部署方案</div>
        <div class="title-description">
          请留下您的相关信息，收到后我们会有专业服务人员与您联系
        </div>
      </template>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
      >
        <a-form-model-item prop="name" label="1. 您的姓名">
          <a-input placeholder="请输入您的姓名" v-model="formData.name" />
        </a-form-model-item>
        <a-form-model-item prop="phone" label="2. 您的电话">
          <a-input placeholder="请输入您的电话" v-model="formData.phone" />
        </a-form-model-item>
        <a-form-model-item prop="note" label="3. 请简要描述您的需求">
          <a-textarea
            placeholder="希望我们能为贵司提供什么，可在此简要说明～"
            v-model="formData.note"
            :rows="6"
          />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button class="button" :loading="confirmLoading" @click="handleOk"
          >提交</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "Modal",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmLoading: false,
      // 布局
      labelCol: {
        xs: { span: 7 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      formData: {
        name: "",
        phone: "",
        note: "",
      },
      // 表单校验规则
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        phone: [
          {
            required: true,
            message: "请输入正确的电话",
            trigger: "blur",
            pattern:
              /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/,
          },
        ],
        note: [
          { required: true, message: "请简要描述您的需求", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.$apis
            .potentialCustomerAdd(this.formData)
            .then((res) => {
              if (res.code === "200") {
                this.$message.success('提交成功')
                this.$emit("close");
              }
            })
            .finally((e) => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancle() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="less">
.title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
}
.title-description {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #bfbfbf;
}
/deep/ .ant-form-item-label > label {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #606c80;
}
/deep/ .ant-modal-footer {
  text-align: center;
}
.button {
  width: 160px;
  height: 48px;
  background: linear-gradient(to right, #708aff, #4a61ff);
  color: #fff;
  border: none;
}
.button:hover {
  background: linear-gradient(to right, #5e7af9, #354cdf);
}
.button:active {
  background: linear-gradient(to right, #536cdc, #1e2f9f);
}
</style>
